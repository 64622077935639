require("@rails/ujs").start()
require("@rails/activestorage").start()

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import '../../assets/stylesheets/application'
import 'bootstrap/dist/js/bootstrap'
import './slick'
import './gallery'
import './ekko-lightbox'
import './inputmask'
import './suggestions'
import './particleground'
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

const images = require.context('../images', true)
